import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import { faMicrochip, faMemory, faDiscDrive, faLayerPlus, faHdd, faBatteryBolt, faFan, faCog, faSitemap, } from '@fortawesome/pro-thin-svg-icons'
import CustomLink from './CustomLink'

class PlatformDetails extends React.Component {
  render() {
    return (
        <MDBContainer className={`${this.props.divider}`}>
          <MDBRow className="py-5">
            <MDBCol lg="6" className="d-flex flex-column pr-5">
              <h3 className="font-alt font-w-400 mb-4 title-xs-medium title-large letter-spacing-1 mb-2">
                {this.props.title}
              </h3>

              {this.props.subtitle &&
                <div className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium title-medium mb-3"  style={{color: "#fd0707"}}>
                  {this.props.subtitle}
                </div>
              }

              <p className="font-w-400 text-medium">{this.props.description}</p>
              <GatsbyImage image={this.props.image} alt={this.props.alt} />
            </MDBCol>

            {this.props.specs &&
              <MDBCol lg="6" className="mt-5 mt-lg-0">
                <MDBCard className="p-4 bg-white-grey-gradient">
                  <h4 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-2 ico-color">
                    Specification
                  </h4>

                  <ul className="fa-ul text-medium">
                    {this.props.specs.map((feature, index) => (
                      <li key={index} className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={
                              feature.icon == 'microchip'
                                ? faMicrochip
                                : feature.icon == 'memory'
                                ? faMemory
                                : feature.icon == 'disc-drive'
                                ? faDiscDrive
                                : feature.icon == 'layer-plus'
                                ? faLayerPlus
                                : feature.icon == 'hdd'
                                ? faHdd
                                : feature.icon == 'battery-bolt'
                                ? faBatteryBolt
                                : feature.icon == 'fan'
                                ? faFan
                                : feature.icon == 'architecture'
                                ? faSitemap
                                : faCog
                            }
                            className="fa-fw mr-2 ico-color"
                            size="2x"
                            title={feature.title}
                          />
                        </span>
                        <div className="ml-3">{feature.description}</div>
                      </li>
                    ))}
                  </ul>

                  <div className="btn-toolbar justify-content-sm-center">
                    {!this.props.eol && (
                      <a
                        href={ 'https://go.unicomengineering.com/Request-A-Quote.html?pg-name=' + this.props.title .replace(/ /g, '-') .replace(/[”"'()]/g, '') }
                        className="nav-link btn btn-mdb-color btn-sm-block"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                        Request a quote
                      </a>
                    )}

                    <CustomLink
                      link={this.props.link}
                      className="nav-link btn btn-mdb-color btn-sm-block noeffect"
                    >
                      <FontAwesomeIcon icon={this.props.link.indexOf("static") == -1 ? faChevronRight : faDownload} className="mr-2" />
                      {this.props.linktext}
                    </CustomLink>
                  </div>
                </MDBCard>
              </MDBCol>
            }
          </MDBRow>
        </MDBContainer>
    )
  }
}
export default PlatformDetails